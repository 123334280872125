import React, { useContext, useState } from "react";
import Drop from "../../Drop/Drop";
import { SemiRhombToClose, SemiRhombToOpen } from "../../../assets/icons/icons";
import { motion } from "framer-motion";
import '../SideMenu.css'
import '../../Popup/Popup.css'
import { Link, useNavigate } from "react-router-dom";
import mainApi from "../../../assets/api/MainApi";
import { ConfigContext } from "../../../assets/contexts/configContext";
import { CATALOG_MAIN_LINK } from "../../../assets/utils/constants";
import MiniPreloader from "../../MiniPreloader/MiniPreloader";

export default function FirstStepDrops({

    categories, handleNavLinkClick,
    isOpen, popup, handleClosePopup
}) {
    const config = useContext(ConfigContext)
    const { initialConstants } = config
    const { SHOP_ID_FOR_API, } = initialConstants

    const navigate = useNavigate();

    const [selectedCategory, setSelectCategory] = useState(undefined);
    const [isPreloaderVisible, setPreloaderVisible] = useState(false);
    const [subcategories, setSubcategories] = useState(undefined);

    function handleSelectCategory(item) {
        if (selectedCategory && selectedCategory._id === item._id) {
            setSubcategories(null);
            setSelectCategory(null)
            return
        };

        if (item.is_final) {
            navigate(`/${CATALOG_MAIN_LINK}/${item.translit_name}`);
            //   closeDropdown()
            return
        }
        setSelectCategory(item);
        setPreloaderVisible(true);

        mainApi
            .getCategories({
                limit: 25,
                shop_id: SHOP_ID_FOR_API,
                _id: item._id,
            })
            .then((res) => {
                setSubcategories(res.data);
                setSelectCategory(prevValue => ({
                    ...prevValue,
                    subcategories: res.data,
                }))
                setTimeout(() => {
                    setSelectCategory(prevValue => ({
                        ...prevValue,
                        subcategories_open: true,
                    }))
                }, 100);

                if (res.data.length === 0) {
                    navigate(`/${CATALOG_MAIN_LINK}/${item.translit_name}`);
                    //   closeDropdown()

                }
            })
            .catch((err) => {
                console.log(err);
            })
            .finally(() => {
                setPreloaderVisible(false);
            });
    }


    return (
        <motion.div
            initial={{
                opacity: 0,
                visibility: "hidden",
            }}
            animate={{
                opacity: isOpen ? 1 : 0,
                visibility: isOpen ? "visible" : "hidden",
            }}
            transition={{ duration: 0.2 }}
            className={popup && popup.categories ? 'side-menu__box-with-popup' : 'side-menu__box'}
        >

            {Array.isArray(categories) ? categories.sort((item1, item2) => {
                // If item1 is "Новые товары", it should come first
                if (item1.name === "Новые товары") return -1;
                // If item2 is "Новые товары", it should come first
                if (item2.name === "Новые товары") return 1;
                // Otherwise, sort alphabetically
                return item1.name.localeCompare(item2.name);
            }).map((item, i) => (
                <div className="side-menu__content">
                    <button type='button' name='house' onClick={() => handleSelectCategory(item)}
                        className='side-menu__box-btn'>
                        {item.name}
                        {!item.is_final ?
                            <svg className={`side-menu__box-btn-arrow ${selectedCategory && selectedCategory.translit_name === item.translit_name ? 'side-menu__box-btn-arrow_selected' : ''}`} width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M12.4149 4.77664L11.7929 4.16165C11.5999 3.97765 11.2959 3.97765 11.1029 4.16165L7.03186 8.13864L3.50689 4.16269C3.31789 3.95569 2.9959 3.94464 2.7929 4.13864L2.1549 4.79367C1.9549 4.98367 1.94787 5.30066 2.13787 5.49966L6.68585 10.2757C6.87685 10.4757 7.19386 10.4837 7.39386 10.2927L12.4149 5.49966C12.6209 5.30366 12.6209 4.97364 12.4149 4.77664Z" fill="#868686" />
                            </svg>
                            : null}

                    </button>
                    {selectedCategory && selectedCategory.translit_name === item.translit_name ?
                        isPreloaderVisible ? (
                            <div className="catalog-drop__preloader">
                                <MiniPreloader />
                            </div>
                        ) :
                            selectedCategory.subcategories && selectedCategory.subcategories.length > 0 ?
                                <Drop
                                    items={selectedCategory.subcategories}
                                    selectedCategory={selectedCategory}
                                    isOpen={selectedCategory.subcategories_open}
                                />
                                :
                                null
                        : null}
                </div>

            )) : null}

            {
                popup && popup.categories ?
                    <button className='popup__box-close-btn' type='button'
                        onClick={() => handleClosePopup('categories')}>Закрыть</button>
                    : null
            }
        </motion.div>
    )
}
