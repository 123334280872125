import "./Cart.css";
import { Order } from "./Order/Order";
import { useContext, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import Goods from "./Goods/Goods";
import PersonalInfo from "./PersonalInfo/PersonalInfo";
import mainApi from "../../assets/api/MainApi";
import PromoCheckPopup from "./PromoCheckPopup/PromoCheckPopup";
import OrderPopup from "./OrderPopup/OrderPopup";
import { formatStringOrNull, getCookieWithShop, getFinalPrice, getTotal, sendMetriс, setCookieWithShop } from "../../assets/utils/utils";
import OrderErrorPopup from "./OrderErrorPopup/OrderErrorPopup";
import { useSearchParams } from "react-router-dom";
import { CartContext } from "../../assets/contexts/cartContext";
import { ConfigContext } from "../../assets/contexts/configContext";
import { NO_DELIVERY_TYPE, OWN_DELIVERY_TYPE } from "../../assets/utils/constants";

function Cart() {
  const cart_context = useContext(CartContext)
  const config = useContext(ConfigContext)
  const { initialConstants } = config
  const { SHOP_ID_FOR_API, SHOP_NAME, CONSTANTS, DELIVERIES } = initialConstants
  const cartLocalStorageKey = `cart_${SHOP_ID_FOR_API}`;
  const {
    cart,
    handleAddToCart,
    handleRemoveFromCart,
    setCart,
  } = cart_context
  const { SHOW_DELIVERY = true } = CONSTANTS || {}
  const [products, setProducts] = useState(undefined);
  const [cartUID, setCartUID] = useState(getCookieWithShop('cart_uid_', SHOP_ID_FOR_API))
  console.log('Cart UID for shop', SHOP_ID_FOR_API, ':', cartUID);
  const clientUID = getCookieWithShop('client_uid_', SHOP_ID_FOR_API);

  const [searchParams, setSearchParams] = useSearchParams();
  const [step, setStep] = useState(0);

  const [popups, setPopups] = useState({
    order_created: false,
    order_created_error: false,
  });

  const [preloaders, setPreloaders] = useState({
    order_creating: false,
    calc: false,
  });

  const [promo, setPromo] = useState({
    value: "",
    isValid: false,
  });

  const [createdOrder, setCreatedOrder] = useState(undefined);

  const [personal, setPersonal] = useState({
    phone: "",
    first_name: "",
    last_name: "",
    email: "",
    submit_type: "phone",
    // type: 'free',
    checkbox: false,
  });

  const [delivery, setDelivery] = useState({
    type: DELIVERIES[0],
    address: '',
    hint_selected: '',
    entrance: '',
    entry_code: '',
    floor: '',
    apartment: '',
    comment: '',
  });

  const [personalValidity, setPersonalValidity] = useState({
    phone: {},
    email: {},
  });

  const [total, setTotal] = useState({
    PRICE: 0,
    ITEMS_PRICE: 0,
    ITEMS_DISCOUNT: 0,
    ITEMS_COUNT: 0,
    PRODUCTS: 0,
    PROMO_DISCOUNT: 0,
    DELIVERY_PRICE: 0,
  });


  function handleNextStep() {
    switch (step) {
      case 0:
        setStep((prevValue) => prevValue + 1);
        window.scrollTo(0, 0);
        sendMetriс("reachGoal", `CREATE_ORDER_${step + 1}`);
        break;

      case 1:
        setPreloaders((prevValue) => ({
          ...prevValue,
          order_creating: true,
        }));
        const cartPrevArray = cart;

        mainApi
          .createOrder({
            shop_id: SHOP_ID_FOR_API,
            email: personal.email,
            phone: personal.phone.replace(/\D/g, ""),
            data: cart.map((item) => {
              return {
                item_id: item._id,
                options: item.options ? item.options : [],
                amount: `${item.count}`,
              };
            }),
            delivery_type: delivery.type.delivery_type,
            delivery_data: delivery.type.delivery_type === OWN_DELIVERY_TYPE ? {
              address: delivery.hint_selected,
              additional_address_data: delivery.entrance || delivery.entry_code || delivery.floor || delivery.apartment || delivery.comment ?
                {
                  entrance: formatStringOrNull(delivery.entrance),
                  entry_code: formatStringOrNull(delivery.entry_code),
                  floor: formatStringOrNull(delivery.floor),
                  apartment: formatStringOrNull(delivery.apartment),
                  comment: formatStringOrNull(delivery.comment),
                } : null,
            } : null,
            first_name: personal.first_name,
            last_name: personal.last_name,
            submit_type: personal.submit_type,

            // payment_type: 'bank_card', //!!!! ОБНОВИТЬ КОГДА ПОДКЛЮЧИМ ПЛАТЕЖКУ
            promo_code: promo.isValid && promo.value ? promo.value : null,
            return_url: `${window.location.href}`,
            options: [],
            cart_uid: cartUID,
            client_uid: clientUID,
          })
          .then((res) => {
            console.log(res);

            if (res.url) {
              window.location.href = res.url;
            } else {
              console.log(res);
              setCreatedOrder(res);
              setPopups((prevValue) => ({
                ...prevValue,
                order_created: true,
              }));
              const timestamp = Date.now() + Math.random().toString().slice(2, 11);
              setCookieWithShop('cart_uid_', SHOP_ID_FOR_API, timestamp, 365);
              setCartUID(getCookieWithShop('cart_uid_', SHOP_ID_FOR_API))
              let cartArray = [];
              setCart(cartArray);
              localStorage.setItem(cartLocalStorageKey, JSON.stringify(cartArray));

              setStep(0);
              setPromo({
                value: "",
                isValid: false,
              });
              sendMetriс("reachGoal", "CREATE_ORDER_2");
              mainApi
                .getManyItems({
                  data: {
                    shop_id: SHOP_ID_FOR_API,
                    ids: cartPrevArray.map((item) => item._id),
                  },
                })
                .then((res2) => {
                  const productsInCart = cartPrevArray.map((item) => {
                    let product = res2.data.filter(
                      (product_item) => product_item._id === item._id
                    )[0];
                    return {
                      ...item,
                      ...product,
                    };
                  });
                  window.dataLayer.push({
                    ecommerce: {
                      currencyCode: "RUB",
                      purchase: {
                        products: productsInCart.map((product, i) => {
                          return {
                            id: product?._id,
                            name: `${product?.name} ${product?._id}`,
                            //   category: res
                            //     ? `${res.category.name}/${res.sub_category.title.ru}`
                            //     : "",
                            price: getFinalPrice(product.options && product.options.length === 1 ? {
                              price: product.options[0].price,
                              ...product.price_data,
                            } : product.price_data, promo),
                            quantity: product.count,
                            // variant: product.selected_size ? product.selected_size.name : 'Без размера'
                            options: product.options,
                          };
                        }),
                        actionField: {
                          id: `№${("00000000" + res.ai_id).slice(-8)}`,
                          goal_id: 301991107,
                        },
                      },
                    },
                  });
                })
                .catch((err) => {
                  console.log(err);
                });
            }
          })
          .catch((err) => {
            console.log(err);
            setPopups((prevValue) => ({
              ...prevValue,
              order_created_error: true,
            }));
          })
          .finally(() => {
            setPreloaders((prevValue) => ({
              ...prevValue,
              order_creating: false,
            }));
          });

        break;

      default:
        break;
    }
  }

  function handleStepBack() {
    if (step !== 0) {
      setStep((prevValue) => prevValue - 1);
    }
  }

  useEffect(() => {
    if (!cart || cart.length === 0) return;
    setPreloaders((prevValue) => ({
      ...prevValue,
      calc: true,
    }));
    setTotal((prevValue) => ({
      ...prevValue,
      NOT_DELIVERABLE: false,
      DELIVERY_TIME: null,
    }));
    mainApi
      .calculateOrder({
        shop_id: SHOP_ID_FOR_API,
        data: cart.map((item) => {
          return {
            item_id: item._id,
            options: item.options ? item.options : [],
            amount: `${item.count}`,
          };
        }),
        promo_code: promo.isValid && promo.value ? promo.value : null,
        delivery_type: delivery.type && delivery.type.delivery_type !== NO_DELIVERY_TYPE && delivery.hint_selected ? delivery.type.delivery_type : null,
        delivery_data: delivery.type && delivery.type.delivery_type !== NO_DELIVERY_TYPE && delivery.hint_selected ? {
          address: delivery.hint_selected,
        } : null

      })
      .then((res) => {
        setTotal((prevValue) => ({
          ...prevValue,
          PRICE: res.total_price,
          ITEMS_PRICE: res.items_price,
          ITEMS_DISCOUNT: res.items_discount,
          ITEMS_COUNT: res.total_items,
          PROMO_DISCOUNT: res.discount,
          DELIVERY_PRICE: res.delivery_price,
          NOT_DELIVERABLE: false,
          DELIVERY_TIME: res.delivery_time ? res.delivery_time.title?.ru : null,

        }));
        console.log(res);
      })
      .catch((err) => {
        setTotal((prevValue) => ({
          ...prevValue,
          NOT_DELIVERABLE: true,
          DELIVERY_TIME: null,
        }));
        console.log(err);
      })
      .finally(() => {
        setPreloaders((prevValue) => ({
          ...prevValue,
          calc: false,
        }));
      })
  }, [delivery.hint_selected, delivery.type, cart, promo]);

  const isPersonalValid =
    personal.phone &&
    personal.first_name &&
    personal.last_name &&
    personal.email &&
    personalValidity.email.validState &&
    personalValidity.phone.validState &&
    personal.checkbox &&
    (delivery && (delivery.type.delivery_type === OWN_DELIVERY_TYPE ? delivery.hint_selected : true));

  useEffect(() => {
    if (!searchParams) return;

    const paid = JSON.parse(searchParams.get("paid"));
    const order = JSON.parse(searchParams.get("order"));
    console.log({ paid, order });
    if (!paid && order) {
      let formattedPhoneValue = "";
      if (["7", "8", "9"].indexOf(order.phone[0]) > -1) {
        if (order.phone[0] === "9") order.phone = "7" + order.phone;

        let firstSimbols = order.phone[0] === "8" ? "8" : "+7";
        formattedPhoneValue = firstSimbols + " ";

        if (order.phone.length > 1) {
          formattedPhoneValue += "(" + order.phone.substring(1, 4);
        }
        if (order.phone.length >= 5) {
          formattedPhoneValue += ") " + order.phone.substring(4, 7);
        }
        if (order.phone.length >= 8) {
          formattedPhoneValue += "-" + order.phone.substring(7, 9);
        }
        if (order.phone.length >= 10) {
          formattedPhoneValue += "-" + order.phone.substring(9, 11);
        }
      } else {
        formattedPhoneValue = "+" + order.phone.substring(0, 16);
      }
      setPersonal((prevValue) => ({
        ...prevValue,
        phone: formattedPhoneValue,
        email: order.email,
        first_name: order.first_name,
        last_name: order.last_name,
        submit_type: order.submit_type,
        // type: order.address.type,
        checkbox: true,
      }));
      setStep(1);
      setPersonalValidity((prevValue) => ({
        ...prevValue,
        phone: {
          errorMessage: "",
          validState: true,
        },
        email: {
          errorMessage: "",
          validState: true,
        },
      }));
      if (order.promo_code) {
        setPromo({
          value: order.promo_code,
          isValid: true,
          discount: order.discount,
        });
      }

      searchParams.delete("order");
      searchParams.delete("paid");
      setSearchParams(searchParams);
    } else if (paid && order) {

      const cartPrevArray = cart;
      setCreatedOrder(order);
      setPopups((prevValue) => ({
        ...prevValue,
        order_created: true,
      }));

      const timestamp = Date.now() + Math.random().toString().slice(2, 11);
      setCookieWithShop('cart_uid_', SHOP_ID_FOR_API, timestamp, 365);
      setCartUID(getCookieWithShop('cart_uid_', SHOP_ID_FOR_API))

      setTimeout(() => {
        let cartArray = [];
        setCart(cartArray);
        localStorage.removeItem(cartLocalStorageKey);
      }, 500);

      setStep(0);
      setPromo({
        value: "",
        isValid: false,
      });
      sendMetriс("reachGoal", "CREATE_ORDER_2");
      window.dataLayer.push({
        ecommerce: {
          currencyCode: "RUB",
          purchase: {
            products: cartPrevArray.map((product, i) => {
              console.log({ product })
              return {
                id: product?._id,
                name: `${product?.name} ${product?._id}`,
                //   category: res
                //     ? `${res.category.name}/${res.sub_category.title.ru}`
                //     : "",
                price: getFinalPrice(product.options && product.options.length === 1 ? {
                  price: product.options[0].price,
                  ...product.price_data,
                } : product.price_data, promo),
                quantity: product.count,
                // variant: product.selected_size ? product.selected_size.name : 'Без размера'
                options: product.options,
              };
            }),
            actionField: {
              id: `№${("00000000" + order.ai_id).slice(-8)}`,
              goal_id: 301991107,
            },
          },
        },
      });
      searchParams.delete("order");
      searchParams.delete("paid");
      setSearchParams(searchParams);
    }
  }, [searchParams]);

  useEffect(() => {
    console.log({ cart })
  }, [cart])

  return (
    <div className="cart">
      <Helmet>
        <title>{SHOP_NAME} - Корзина</title>
      </Helmet>
      <h2 className="cart__title">
        {step === 0 ? "Корзина" : ""}
        {step === 1 ? "Оформление заказа" : ""}
      </h2>
      <div className="cart__content">
        <div className="cart__steps">
          {step > 0 ? (
            <button
              className="cart__go-back"
              type="button"
              onClick={handleStepBack}
            >
              <p className="cart__go-back-text">‹ Назад</p>
            </button>
          ) : null}
          {step === 0 ? (
            <Goods
              cart={cart}
              handleAddToCart={handleAddToCart}
              handleRemoveFromCart={handleRemoveFromCart}
              products={products}
              setProducts={setProducts}
            />
          ) : null}

          {step === 1 ? (
            <PersonalInfo
              personal={personal}
              setPersonal={setPersonal}
              personalValidity={personalValidity}
              delivery={delivery}
              setDelivery={setDelivery}
              setPersonalValidity={setPersonalValidity}
              total={total}
            />
          ) : null}
        </div>
        <Order
          cart={cart}
          step={step}
          handleNextStep={handleNextStep}
          promo={promo}
          setPromo={setPromo}
          isPersonalValid={isPersonalValid}
          isPreloaderVisible={preloaders.order_creating}
          total={total}
          someNotInStock={products && products.length > 0 ? products.filter((item) => {
            const in_stock = item ? item.options.length === 1 ? item.options[0].in_stock : item.price_data.in_stock : null
            if (!in_stock || item.count > in_stock) return true
            else return false
          }).length > 0
            :
            false}
          preloader={preloaders.calc}
        />
      </div>
      <OrderPopup
        isOpen={popups.order_created}
        popupName={"order_created"}
        setOpen={setPopups}
        order={createdOrder}
        ai_id={
          createdOrder ? `№${("00000000" + createdOrder.ai_id).slice(-8)}` : ""
        }
      />
      <OrderErrorPopup
        isOpen={popups.order_created_error}
        popupName={"order_created_error"}
        setOpen={setPopups}
      />
    </div>
  );
}

export default Cart;