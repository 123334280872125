
import { useEffect, useRef, useState } from 'react';
import { motion } from "framer-motion";

import './AddressInput.css';
import Input from '../../../Input/Input';
import MiniPreloader from '../../../MiniPreloader/MiniPreloader';
import mainApi from '../../../../assets/api/MainApi';
const DEBOUNCE_TIME = 1500


// import { handleAddressFormChange } from '../utils/addressFromUtils';

const dropdownVariants = {
    open: {
        clipPath: "inset(0% 0% 0% 0% round 8px)",
        transition: {
            type: "spring",
            bounce: 0,
            duration: 0.5,
            delayChildren: 0.2,
            staggerChildren: 0.05,
        },
    },
    closed: {
        clipPath: "inset(0 90% 90% 10% round 8px)",
        transition: {
            type: "spring",
            bounce: 0,
            duration: 0.3,
        },
    },
};


function AddressInput({ form, setForm, error, hint }) {
    const dropRef = useRef()
    const [preloaders, setPreloaders] = useState({
        hints: false,
    })

    const [addressHints, setAddressHints] = useState(null)
    const [isDropOpen, setDropOpen] = useState(false)

    function handleFocus() {
        setDropOpen(addressHints && addressHints.length > 0)
    }


    useEffect(() => {
        const address = form.address
        if (!address || address.length <= 3 || address === form.hint_selected) return
        const delayDebounceFn = setTimeout(() => {
            setPreloaders(prevValue => ({
                ...prevValue,
                hints: true,
            }))
            mainApi.searchAddress({
                query: address,
            })
                .then((res) => {
                    console.log(res.data)
                    setAddressHints(res.data)
                })
                .catch((err) => {
                    console.log(err)
                })
                .finally(() => {
                    setPreloaders(prevValue => ({
                        ...prevValue,
                        hints: false,
                    }))
                })
        }, DEBOUNCE_TIME)

        return () => {
            clearTimeout(delayDebounceFn);
        }
    }, [form.address])


    function handleHintClick(hint) {
        setPreloaders(prevValue => ({
            ...prevValue,
            hints: true,
        }))
        setForm(prevValue => ({
            ...prevValue,
            hint_selected: hint,
        }))
        mainApi.searchAddress({
            query: hint,
        })
            .then((res) => {
                console.log(res.data)
                setAddressHints(res.data)
            })
            .catch((err) => {
                console.log(err)
            })
            .finally(() => {
                setPreloaders(prevValue => ({
                    ...prevValue,
                    hints: false,
                }))
            })
    }

    useEffect(() => {
        setDropOpen(addressHints && addressHints.length > 0)
    }, [addressHints])

    useEffect(() => {
        function handleOutsideClickClose(evt) {
            if (
                isDropOpen &&
                dropRef.current &&
                !dropRef.current.contains(evt.target)
            ) {
                setDropOpen(false)
            }
        }

        document.addEventListener("mousedown", handleOutsideClickClose);
        return () => {
            document.removeEventListener("mousedown", handleOutsideClickClose);
        };
    });

    const [selectedHintIndex, setSelectedHintIndex] = useState(-1);

    function handleKeyDown(e) {
        if (!addressHints || addressHints.length === 0) return;

        if (e.key === 'Escape') {
            e.preventDefault(); // Prevents scrolling
            setDropOpen(false)
            if (document.activeElement.tagName === 'INPUT') {
                document.activeElement.blur();
            }
            setSelectedHintIndex(-1)
        }
        if (e.key === 'ArrowDown') {
            e.preventDefault(); // Prevents scrolling
            setSelectedHintIndex(prevIndex =>
                prevIndex < addressHints.length - 1 ? prevIndex + 1 : 0
            );
        } else if (e.key === 'ArrowUp') {
            e.preventDefault();
            setSelectedHintIndex(prevIndex =>
                prevIndex > 0 ? prevIndex - 1 : addressHints.length - 1
            );
        } else if (e.key === 'Enter') {
            if (selectedHintIndex >= 0 && selectedHintIndex < addressHints.length) {
                handleHintClick(addressHints[selectedHintIndex]);
                setDropOpen(false); // Close dropdown on selection
                setSelectedHintIndex(-1)
            }
        }
    }

    function handleAddressChange(e) {
        const input = e.target;
        let value = input.value;
        const name = input.name
        switch (name) {
            case 'address': {
                if (value.length > 3) {
                    setPreloaders(prevValue => ({
                        ...prevValue,
                        hints: true,
                    }))
                } else {
                    setPreloaders(prevValue => ({
                        ...prevValue,
                        hints: false,
                    }))
                }
                setForm(prevValue => ({
                    ...prevValue,
                    [name]: value,
                    hint_selected: '',
                }))
                setAddressHints(null)
                break;
            }

            default: {
                setForm(prevValue => ({
                    ...prevValue,
                    [name]: value,
                }))
                break;
            }
        }
    }

    return (
        <div className='address-input' ref={dropRef}>

            {/* <PlatformInput
                value={form.hint_selected ? form.hint_selected : form.address}
                handleChange={(e) => {
                    handleAddressFormChange(e, setForm, setPreloaders, setAddressHints)
                }}
                label={'Адрес'}
                type={'text'}
                name={'address'}
                preloader={preloaders.hints}
                isCorrectState={Boolean(form.hint_selected)}
                handleFocus={handleFocus}
                onKeyDown={handleKeyDown}
            /> */}
            <div className="personal-info__input">
                <Input
                    label={"Улица, дом*"}
                    value={form.hint_selected ? form.hint_selected : form.address}
                    handleChange={handleAddressChange}
                    name={"address"}
                    onKeyDown={handleKeyDown}
                    handleFocus={handleFocus}
                    preloader={preloaders.hints}
                    isCorrectState={Boolean(form.hint_selected)}
                    notCorrectAddress={error}
                    error={error ? { errorMessage: 'К&nbsp;сожалению, доставка по&nbsp;указанному адресу недоступна' } : ''}
                    hint={hint ? `Приблизительное время доставки: ${hint}` : null}
                // error={formValid.email}
                ></Input>
            </div>
            <motion.div
                variants={dropdownVariants}
                className='address-input__hints'
                initial={false}
                animate={isDropOpen ? "open" : "closed"}
            >
                {
                    !preloaders.hints ?
                        addressHints && addressHints.length > 0 ?
                            addressHints.map((hint, i) => (
                                <button
                                    onClick={() => {
                                        handleHintClick(hint)
                                    }}
                                    className={`address-input__hint ${selectedHintIndex === i ? 'address-input__hint_selected' : ''}`}
                                    type='button'
                                    key={`address-input__hint-${hint}-${i}`}
                                >
                                    {hint}
                                </button>
                            ))
                            : null
                        :
                        <MiniPreloader />
                }
            </motion.div>

        </div>

    );
}

export default AddressInput