import { Link } from "react-router-dom";
import LinkTemplate from "../LinkTemplate/LinkTemplate";
import useWindowSize from "../../assets/hooks/useWindowSize";
import { LogoIcon, LogoIconMobile, CartIcon, SearchIcon } from "../../assets/icons/icons";
import "./Header.css";
import { useContext, useRef, useState } from "react";
import { CartContext } from "../../assets/contexts/cartContext";
import { ConfigContext } from "../../assets/contexts/configContext";
import Search from "../Search/Search";

const HEADER = [
  {
    url: "tel:+78613720405",
    name: "+7 86137 2 04 05",
  },
];

function Header() {
  const { width } = useWindowSize();
  const config = useContext(ConfigContext);
  const [searchOpen, setSearchOpen] = useState(false)
  const linksRef = useRef(null);
  const { initialConstants } = config
  const { FIRST_LEVEL_CATEGORIES, HEADER_LINKS = [] } = initialConstants
  console.log(HEADER_LINKS)
  const cart_context = useContext(CartContext)
  const {
    cart,
    // handleAddToCart,
    // handleRemoveFromCart,
    // setCart,
  } = cart_context


  return (
    <div className="header">
      <Link className="header__logo-link" to="/">
        {width > 1000
          ? LogoIcon({
            mainClassName: "header__logo",
            fillClassName: "header__logo-fill",
          })
          : LogoIconMobile({
            mainClassName: "header__logo",
            fillClassName: "header__logo-fill",
          })}
      </Link>
      <div className="header__content" ref={linksRef}>
        <div className="search_pc">
          <button className="header__input-icon" onClick={() => setSearchOpen(true)} type="button">
            <SearchIcon mainClassName={'header__input-icon'} fillClassName={'header__input-icon-fill'} />
            Поиск
          </button>
          <Search searchOpen={searchOpen} setSearchOpen={setSearchOpen} maxWidth={linksRef && linksRef.current ? linksRef.current.offsetWidth + 28 + 20 : '700px'} />
        </div>
        <div className="header__right">
          <button className="header__search-icon-mobile" onClick={() => setSearchOpen(true)} type="button">
            <SearchIcon mainClassName={'header__input-icon'} fillClassName={'header__input-icon-fill'} />
          </button>
          <div className="header__links">
            <LinkTemplate listData={HEADER_LINKS.concat(HEADER)} />
          </div>
          <Link className="header__button-cart" to="/cart">
            <p className="header__cart-text">Корзина</p>
            {CartIcon({
              mainClassName: "header__cart-logo",
              fillClassName: "header__cart-logo-fill",
            })}
            {cart && cart.length > 0 ? (
              <p className="header__cart-count">{cart.length}</p>
            ) : null}
          </Link>
        </div>

      </div>
      <div className="search_mobile">

        <Search searchOpen={searchOpen} setSearchOpen={setSearchOpen} maxWidth={`100%`} />
      </div>
    </div>
  );
}

export default Header;
