import { useContext, useEffect, useState } from "react";
import "./Catalog.css";
import { Link, useLocation, useParams } from "react-router-dom";
import { CATALOG_MAIN_LINK } from "../../assets/utils/constants";
import { buildCategoryPath } from "../../assets/utils/utils";
import { Helmet } from "react-helmet";
import MiniPreloader from "../MiniPreloader/MiniPreloader";
import CategoryCard from "./CategoryCard/CategoryCard";
import Products from "./Products/Products";
import mainApi from "../../assets/api/MainApi";
import { ConfigContext } from "../../assets/contexts/configContext";

const PRODUCTS_LIMIT = 25

function Catalog({ levels }) {
  const config = useContext(ConfigContext)
  const { initialConstants } = config
  const { SHOP_ID_FOR_API, } = initialConstants
  const location = useLocation();
  const [preloaders, setPreloaders] = useState({
    item: true,
  });
  const [category, setCategory] = useState(undefined);

  useEffect(() => {
    if (!levels) return;

    const tree = location.pathname
      .split("/")
      .filter((item) => item !== "" && item !== "catalog");
    setPreloaders((prevState) => ({
      ...prevState,
      item: true,
    }));
    mainApi
      .getCategoriesWithTree({
        shop_id: SHOP_ID_FOR_API,
        data: tree,
      })
      .then((res) => {
        console.log(res)
        const data =
          res.data.length > 0 ? res.data[res.data.length - 1] : undefined;
        setCategory({
          _id: data._id,
          title: data.name,
          path: res.data,
          categories: [],
        });
        if (data) {
          if (!data.is_final) getSubcategories({ _id: data._id });
          getItems({ _id: data._id })
        }
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setPreloaders((prevState) => ({
          ...prevState,
          item: false,
        }));
      });
  }, [levels]);

  function getSubcategories({ _id }) {
    mainApi
      .getCategories({
        limit: PRODUCTS_LIMIT,
        shop_id: SHOP_ID_FOR_API,
        _id,
      })
      .then((res) => {
        setCategory((prevVal) => ({
          ...prevVal,
          categories: res.data,
        }));
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setPreloaders((prevState) => ({
          ...prevState,
          item: false,
        }));
      });
  }

  function getItems({ _id }) {
    mainApi
      .getItems({
        shop_id: SHOP_ID_FOR_API,
        category: _id,
        limit: PRODUCTS_LIMIT,
      })
      .then((res) => {
        console.log(res)
        setCategory((prevVal) => ({
          ...prevVal,
          items: res,
        }));
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setPreloaders((prevState) => ({
          ...prevState,
          item: false,
        }));
      });
  }

  function getMoreProducts() {
    const last_id = category.items.data[category.items.data.length - 1]._id
    setPreloaders((prevState) => ({
      ...prevState,
      more: true,
    }));
    mainApi
      .getItems({
        shop_id: SHOP_ID_FOR_API,
        category: category && category._id ? category._id : null,
        limit: PRODUCTS_LIMIT,
        last_id: last_id,
      })
      .then((res) => {
        console.log(res)
        setCategory((prevValue) => ({
          ...prevValue,
          items: {
            data: prevValue.items.data.concat(res.data),
            is_more: res.is_more,
          },
        }));
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setPreloaders((prevState) => ({
          ...prevState,
          more: false,
        }));
      });
  }

  return (
    <>
      <Helmet>
        <title></title>
      </Helmet>
      {preloaders.item ? (
        <div className="catalog__preloader">
          <MiniPreloader />
        </div>
      ) : category ? (
        <div className="catalog">
          <div className="catalog__content">
            <div className="catalog__crumbs">
              {category.path.map((cat, i) => (
                <Link
                  to={`/${CATALOG_MAIN_LINK}/${buildCategoryPath(
                    category.path,
                    i
                  )}`}
                >
                  <p className="catalog__crumb">
                    {cat.name}
                    {i !== category.path.length - 1 ? ` ›` : ""}
                  </p>
                </Link>
              ))}
            </div>
            <h2 className="catalog__category-title">{category.title}</h2>
            {category.categories && category.categories.length > 0 ?
              <div className="catalog__categories-inside">
                {category.categories.map((cat, i) => (
                  <CategoryCard
                    levels={levels}
                    category={cat}
                    categories={category.categories}
                    i={i}
                  />
                ))}
              </div>
              : null}

            <Products
              items={category.items}
              path={category.path}
            />
            {category && category.items && category.items.is_more ?
              <button className={`catalog__more ${preloaders.more ? 'catalog__more_loading' : ''}`} type='button' onClick={() => {
                getMoreProducts()
              }}>
                {preloaders.more ? <MiniPreloader isLinkColor={true} /> : 'Загрузить еще'}
              </button>
              : null}
          </div>
        </div>
      ) : (
        <div className="catalog__not-found">
          {/* <NotFoundBlock
                text={'Кажется, Вы&nbsp;попытались открыть товар, который пока не&nbsp;поступил к&nbsp;нам в&nbsp;продажу'}
                goToMain={true}
            /> */}
        </div>
      )}
    </>
  );
}

export default Catalog;
