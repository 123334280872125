import { Link } from "react-router-dom";
import "./LinkTemplate.css";

function LinkTemplate({ listData }) {
  return (
    <>
      {listData.map((item, i) => {
        return item.path ? (
          <Link
            className="content_link"
            to={item.path}
            style={{ color: item.color ? item.color : "" }}
            key={`${item.path}-${i}`}
          >
            {item.name}
          </Link>
        ) : item.url ? (
          <a
            href={`${item.url}`}
            className="content_link"
            target="_blank"
            rel="noreferrer"
            style={{ color: item.color ? item.color : "" }}
            key={`${item.url}-${i}`}
          >
            {item.name}
          </a>
        ) : (
          <p
            className="content_link"
            style={{ color: item.color ? item.color : "" }}
            key={`${item.name}-${i}`}
          >
            {item.name}
          </p>
        );
      })}
    </>
  );
}

export default LinkTemplate;
